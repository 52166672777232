import '../styles/index.scss';

console.log('webpack starterkit');

const acc = document.querySelectorAll(".accordion");
let i;

for (i = 0; i < acc.length; i++) {
  const currentAcc = acc[i];
  currentAcc
    .querySelector(".action")
    .addEventListener("click", function(e) {
      e.preventDefault();
      currentAcc.classList.toggle("active");
    });
}